import request from './request'

const api = {
	// 更新配置信息
  setConfig: '/System/setConfig',
  // 获取配置信息
  configinfo: '/System/getConfig',
}

export { request, api }
