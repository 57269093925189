<template>
	<div class="bg-Hight">
		<div class="content">
			<div class="title">
				基本参数
			</div>
			<ul class="list config" element-loading-text="加载中...">
				<li>
					<div class="name">安卓安装包</div>
					<div class="input">
						<el-upload class="fupload" list-type="picture-card" :on-success="handleAvatarSuccess"
							:headers="Headers" :action="ActionUrl">
							<i class="icon iconfont icon-shangchuan"></i>
						</el-upload>
					</div>
				</li>
				<li>
					<div class="appurl">{{form.app_url}}</div>
				</li>
				<li class="ftime">
					<div class="name">安卓下载地址</div>
					<div class="input">
						<el-input clearable type="text" v-model="form.app_down_url" placeholder="请输入安卓下载地址">
						</el-input>
					</div>
				</li>
				<li class="ftime">
					<div class="name">安卓APP版本</div>
					<div class="input">
						<el-input clearable type="text" v-model="form.app_version" placeholder="请输入版本">
						</el-input>
					</div>
				</li>
				<li class="ftime">
					<div class="name">APP更新内容</div>
					<div class="input">
						<el-input clearable type="textarea" v-model="form.update_content" placeholder="请输入更新内容">
						</el-input>
					</div>
				</li>
				<li class="ftime">
					<div class="name">距办结绿色预警时间</div>
					<div class="input">
						<el-input clearable type="text" v-model="form.warning_time_one" placeholder="请输入距离办结绿色预警时间">
						</el-input>
					</div>
				</li>
				<li class="ftime">
					<div class="name">距办结黄色预警时间</div>
					<div class="input">
						<el-input clearable type="text" v-model="form.warning_time_two" placeholder="请输入距离办结黄色预警时间">
						</el-input>
					</div>
				</li>
				<li class="ftime">
					<div class="name">超办一红色预警时间</div>
					<div class="input">
						<el-input clearable type="text" v-model="form.overdue_time_one" placeholder="请输入超期办结红色预警时间">
						</el-input>
					</div>
				</li>
				<li class="ftime">
					<div class="name">超办二红色预警时间</div>
					<div class="input">
						<el-input clearable type="text" v-model="form.overdue_time_two" placeholder="请输入超期办结红色预警时间">
						</el-input>
					</div>
				</li>
				<!-- <li class="ftime">
					<div class="name">超办三红色预警时间</div>
					<div class="input">
						<el-input clearable type="text" v-model="form.overdue_time_three" placeholder="请输入超期办结红色预警时间">
						</el-input>
					</div>
				</li> -->
				<li class="ftime">
					<div class="name">默认密码</div>
					<div class="input">
						<el-input clearable type="text" v-model="form.password" placeholder="请输入默认密码">
						</el-input>
					</div>
				</li>
			</ul>
			<div class="title">
				<p @click="selectab('督查室',form.guide_position_type_0)" :class="[tab=='督查室'?'current':'']">督查室新人指引</p>
				<p @click="selectab('县纪委',form.guide_position_type_1)" :class="[tab=='县纪委'?'current':'']">县纪委新人指引</p>
				<p @click="selectab('区域网格总长',form.guide_position_type_2)" :class="[tab=='区域网格总长'?'current':'']">
					区域网格总长新人指引</p>
				<p @click="selectab('区域网格长',form.guide_position_type_3)" :class="[tab=='区域网格长'?'current':'']">区域网格长新人指引
				</p>
				<p @click="selectab('网格长',form.guide_position_type_4)" :class="[tab=='网格长'?'current':'']">网格长新人指引</p>
				<p @click="selectab('网格员',form.guide_position_type_5)" :class="[tab=='网格员'?'current':'']">网格员新人指引</p>
			</div>
			<div v-loading="loading">
				<texts @Submitinput="Submitinput" :contentText="contentText"></texts>
			</div>
			<ul class="list">
				<li>
					<div class="input">
						<el-button @click="SubmitForm" color="#5473E8" class="el-white" style="color: white"
							size="small">保存修改</el-button>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import {
		request,
		api
	} from "@/api/config";
	import {
		ElMessage
	} from 'element-plus'
	import texts from './text.vue'
	export default {
		components: {
			texts
		},
		data() {
			return {
				Headers: {
					authorization: '',
					appid:'',
					nonce:'',
					timestamp:'',
					sign:''
				},
				ActionUrl: '',
				loading: false,
				contentText: '',
				tab: '督查室',
				form: {
					avatar: '',
					app_url: '',
					warning_time_one: '',
					warning_time_two: '',
					overdue_time_one: '',
					overdue_time_two: '',
					password: '',
					app_version: '',
					app_down_url:'',
					update_content: '',
					position: '',
					guide: '',
					overdue_time_three: '',
					guide_position_type_0: '',
					guide_position_type_1: '',
					guide_position_type_2: '',
					guide_position_type_3: '',
					guide_position_type_4: '',
					guide_position_type_5: ''
				}
			}
		},
		created() {
			// 上转图片url
			this.Headers.authorization = sessionStorage.getItem('token')
			let param = JSON.parse(sessionStorage.getItem('param'))
			this.Headers.appid = param.appid
			this.Headers.nonce = param.nonce
			this.Headers.timestamp = param.timestamp
			this.Headers.sign = param.sign
			this.ActionUrl = sessionStorage.getItem('baseURL') + '/System/FormUpload'

			this.getconfig()
		},
		methods: {
			// 上转图片
			handleAvatarSuccess(res, file) {
				this.form.app_url = res.data
			},
			// 监听描述内容
			Submitinput(e) {
				if (this.tab == '督查室') {
					this.form.guide_position_type_0 = e
				} else if (this.tab == '县纪委') {
					this.form.guide_position_type_1 = e
				} else if (this.tab == '区域网格总长') {
					this.form.guide_position_type_2 = e
				} else if (this.tab == '区域网格长') {
					this.form.guide_position_type_3 = e
				} else if (this.tab == '网格长') {
					this.form.guide_position_type_4 = e
				} else if (this.tab == '网格员') {
					this.form.guide_position_type_5 = e
				}
			},
			// 选项卡
			selectab(val, text) {
				let that = this
				this.loading = true
				this.contentText = text
				this.tab = val
				setTimeout(function() {
					that.loading = false
				}, 500)

			},
			// 获取配置
			getconfig() {
				this.$http.post(api.configinfo)
					.then((res) => {
						if (res.code == 1) {
							this.form = res.data
							this.contentText = res.data.guide_position_type_0
							this.loading = false
						}
					})
			},
			// 修改
			SubmitForm() {
				this.$http.post(api.setConfig, this.form)
					.then((res) => {
						if (res.code == 1) {
							this.tab = "督查室"
							ElMessage({
								type: 'success',
								message: '修改成功',
							})
							this.getconfig()
						}
					})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.list {
		padding-left: 60px;

		.appurl {
			padding-left: 150px;
			color: #666;
		}

		li {
			width: 100%;
			font-size: 14px;
			display: flex;
			margin-bottom: 18px;

			.name {
				padding: 0 12px 0 0;
				line-height: 32px;
				width: 150px;
			}

			.input {
				i {
					font-size: 45px;
				}

				.fupload {
					display: inline-block;
				}
			}
		}
	}

	.content {
		padding: 20px;

		.title {
			color: #000;
			margin: 30px 0;
			font-size: 15px;
			width: 100%;
			display: flex;
			border-bottom: 1px solid #f2f2f2;
			align-items: center;

			.current {
				border-bottom: 2px solid #5473E8;
			}

			p {
				height: 40px;
				line-height: 40px;
				margin-right: 30px;
				text-align: center;
				cursor: pointer;
			}
		}
	}
</style>
